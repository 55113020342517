<script>
import {messageSwal} from "@/components/composables/messages";
import store from "@/store";

export default {

    props: {
        types: {
            type: Array,
            required: true
        },
        url: {
            type: String,
            required: true
        },
        api: {
            type: String,
            required: true
        },
        session: {
            type: String,
            required: false
        },
        id: {
            type: Number,
            required: true
        },
        extra: {
            type: Array,
            required: false
        },
        data: {
            type: Object,
            required: false
        },
    },
    methods: {
        async destroy() {
            const result = await messageSwal(
                "Atenção!",
                "Tem certeza que deseja deletar?",
                "warning"
            )
            if (result) {
                const data = {
                    session: this.session,
                    id: this.id
                };
                await store.dispatch('api/delete', data)
            }
        },
        copyLink() {
            navigator.clipboard.writeText(this.data.link);
            this.$notifySuccess('Link copiado com sucesso!')
        }
    }
}


</script>

<template>
    <span class="d-flex justify-content-around">
        <router-link :to="url + 'detalhes/' + id" v-if="types.indexOf('details') > -1">
            <i class="fa fa-eye text-muted mx-1" title="Visualizar"></i>
        </router-link>

        <router-link :to="url + 'cadastrar/' + id" v-if="types.indexOf('edit') > -1">
            <i class="fa fa-pencil-alt text-info mx-1" title="Editar"></i>
        </router-link>

        <span @click="destroy()" v-if="types.indexOf('delete') > -1">
            <i class="fa fa-trash text-danger mx-1" style="cursor:pointer" title="Deletar"></i>
        </span>

        <template v-for="(item, index) in extra">
            <span :key="index" @click="$emit(item.callback,id)">
                <i :class="item.class" style="cursor:pointer" title="item.title"></i>
            </span>
        </template>

        <span v-if="types.indexOf('copy') > -1">
            <i class="fa fa-link text-muted mx-1" style="cursor:pointer" @click="copyLink()" title="Copiar Link"></i>
        </span>

         <span v-if="types.indexOf('preview') > -1">
             <a :href="data.link" target="_blank">
                <i class="fa fa-search text-muted mx-1" title="Preview"></i>
             </a>
        </span>

    </span>
</template>
